<template>
  <v-container>
    <v-form @submit.prevent="edit" ref="form">
      <v-card width="400" class="mx-auto mt-5">
        <v-card-title class="pb-0">
          <h3>Profil</h3>
        </v-card-title>
        <v-card-text>

          <v-alert v-if="error"
            v-model="error"
            border="left"
            dismissible
            type="error"
          >{{errorText}}</v-alert>

          <v-text-field
            v-model="username"
            label="Nom d'utilisateur"
            hide-details
            prepend-icon="mdi-account-circle"
            class="shrink"
            disabled
          ></v-text-field>

          <v-text-field
            v-model.trim="email"
            autofocus
            label="Adresse email"
            hide-details
            prepend-icon="mdi-at"
            class="shrink"
          ></v-text-field>

          <v-text-field
            v-model="password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            label="Mot de passe"
            hide-details
            prepend-icon="mdi-lock"
            class="shrink"
            @click:append="show = !show"
          ></v-text-field>

          <v-text-field
            v-model.trim="phone"
            label="Téléphone"
            hide-details
            prepend-icon="mdi-phone"
            class="shrink"
          ></v-text-field>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn type="submit" color="success">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data: function() {
    return {
      dataProxy: {
        email: null,
        phone: null
      },
      error: false,
      errorText: '',
      password: '',
      show: false
    };
  },

  computed: {
    email: {
      get: function() {
        return (this.dataProxy.email) ? this.dataProxy.email : this.$store.getters.getUserProfile.email;
      },
      set: function(value) {
        this.dataProxy.email = value; // @todo
      }
    },
    phone: {
      get: function() {
        return (this.dataProxy.phone) ? this.dataProxy.phone : this.$store.getters.getUserProfile.phone;
      },
      set: function(value) {
        this.dataProxy.phone = value; // @todo
      }
    },
    username: function() {
      return this.$store.getters.getUserProfile.username;
    }
  },

  methods: {
    edit: function() {
      //this.$refs.form.validate();
      // initialize error alert
      this.error = false;

      const self = this;
      const { username, password, email, phone } = this;
      const userID = this.$store.getters.getUserProfile.id; // current user identifier
      this.$store.dispatch('updateUserProfileRequest', { id: userID, username, password, email, phone })
        .then(function() {
          //self.$router.push('/');
          // @todo show success message
        })
        .catch(function(error) {
          self.errorText = error.message;
          // get server response
          if (error.response.data && error.response.data.message) {
            self.errorText = error.response.data.message;
          }
          self.error = true;
        });
    }
  }
};
</script>